import {useEffect, useState} from "react";
import {getRelatedTopics} from "../api/api";

const RelatedTopics = ({category}) => {
    const [relatedTopics, setRelatedTopics] = useState({tag: null, words: []});

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await getRelatedTopics();
                const words = res[category]['words'];
                const tagId = res[category]['tid'];
                setRelatedTopics({tag: tagId, words});
            } catch (err) {
                console.log(err);
            }
        }

        fetchData();

    }, []);

    function handleClickTopic(word) {
        window.location.href=`https://gamgladthereis.info/?tid=${relatedTopics.tag}&q=${word}`;
    }

    if (!relatedTopics.words.length) return (<div>
        <p>Loading...</p>
    </div>);
    else {
        return (
            <div className="topics-container">
                <div className="tiny-title">
                    <p>Related search topics</p>
                    <p className="sponsored">sponsored</p>
                </div>
                <div className="topics-grid">
                    {relatedTopics.words.map((word, index) => (
                        <div className="related-item"
                           key={index}
                           onClick={() => handleClickTopic(word)}
                        >
                            <p>{word}</p>
                            <span></span>
                        </div>
                    ))}
                </div>
            </div>);
    }
}
export default RelatedTopics;