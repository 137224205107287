import "../styles/Modal.css"

function Modal(){


    return (
        <div className="flex padding">
                <span className="loader"></span>
        </div>
    )

}
export default Modal;